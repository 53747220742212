







































import Vue from "vue";

export default Vue.extend({
  name: "DatePicker",
  data() {
    return {
      menu: false,
      date: ""
    };
  },
  props: {
    readonly: {
      type: Boolean,
      required: true
    },
    rules: {
      type: Array,
      required: true
    },
    item_classes: {
      type: String,
      default: "form-field-input"
    },
    placeholder: {
      type: String,
      default: "Select Date"
    },
    default_date: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hide_details: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "month"
    }
  },
  created() {
    if (this.default_date) {
      // If user add data after cv parsing
      if (this.default_date.includes("/")) {
        const [month, year] = this.default_date.split("/");
        this.date = year.trim();
        // If month exist
        if (month) this.date += `-${month.trim()}`;
      } else this.date = this.default_date; // If data parsed by cv parser
    } else this.date = "";
  },
  methods: {
    process_selected_data(date: string) {
      let final_date = date;
      if (this.type !== "date") {
        const [year, month] = date.split("-");
        final_date = `${month}/${year}`;
      }
      this.$emit("selected_date", final_date);
    },
    clear() {
      this.date = "";
      this.$emit("selected_date", "");
    }
  }
});
